
import { defineComponent, computed, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { checkPermission } from '@/router/authentication';
import useUserList from "@/core/services/compositions/user/useUserList";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { ElNotification } from 'element-plus'
import Pagination from "@/components/Pagination.vue";
import moment from 'moment';
import {
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import { useI18n } from "vue-i18n";
import _ from 'lodash';

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
  },
  setup() {
    // init
    const { t } = useI18n();
    const router = useRouter();
    const { query } = useQuery();

    // breadcrumb
    setNewPageBreadcrumbs(t("management.user.title"), [
      { title: t("management.user.title") }
    ]);

    const gotoCreate = () => {
      router.push({ name: "app.management.user.create" });
    };

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
    }));

    const { data, isValidating, mutate: refreshList, error } = useUserList(() => fetchParams.value);

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    return {
      data,
      isValidating,
      gotoCreate,
      handleChangePage,
      moment,
      checkPermission,
      _
    };
  },
  methods: {}
});
