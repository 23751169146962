import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/media/icons/duotone/General/Edit.svg'


const _hoisted_1 = { class: "fs-2" }
const _hoisted_2 = { class: "fs text-gray-600" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3" }
const _hoisted_5 = { class: "fs-5 fw-bolder" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "svg-icon svg-icon-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")
  const _component_el_descriptions = _resolveComponent("el-descriptions")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_inline_svg = _resolveComponent("inline-svg")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_router_link = _resolveComponent("router-link")
  const _component_el_table = _resolveComponent("el-table")
  const _component_pagination = _resolveComponent("pagination")
  const _component_table_skeleton = _resolveComponent("table-skeleton")
  const _component_el_card = _resolveComponent("el-card")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_el_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { class: "d-flex align-items-center mb-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("management.user.title")), 1),
                _createVNode(_component_el_row, { class: "d-flex justify-content-between" }, {
                  default: _withCtx(() => [
                    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("management.user.subtitle")), 1),
                    _createVNode("div", _hoisted_3, [
                      (_ctx.checkPermission('user-store').isGranted)
                        ? (_openBlock(), _createBlock("a", {
                            key: 0,
                            class: "btn btn-sm btn-primary me-4",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gotoCreate()))
                          }, " Create "))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_table_skeleton, {
          loading: !_ctx.data || _ctx.isValidating
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table, {
              class: "Client-table mt-8 fs-7",
              data: _ctx.data?.data,
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "expand" }, {
                  default: _withCtx((scope) => [
                    _createVNode("div", _hoisted_4, [
                      _createVNode("p", _hoisted_5, _toDisplayString(_ctx._.upperFirst(scope.row.userable_type)) + " Info", 1),
                      _createVNode(_component_el_descriptions, {
                        size: "small",
                        column: "3",
                        border: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_descriptions_item, { label: "Name" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.row.userable.name), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_descriptions_item, { label: "Email" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.row.userable.email), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_descriptions_item, { label: "Phone" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.row.userable.phone), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_descriptions_item, { label: "Address" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.row.userable.address), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_descriptions_item, { label: "Status" }, {
                            default: _withCtx(() => [
                              _createVNode("span", {
                                class: 'badge ' + (
                      scope.row.userable.is_active == true ? 'badge-light-success' : 'badge-light-danger')
                              }, _toDisplayString(scope.row.userable.is_active == true ? 'ACTIVE' : 'NOT ACTIVE'), 3)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  "header-align": "center",
                  prop: "email",
                  label: "Email"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.email), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  "header-align": "center",
                  prop: "name",
                  label: "Name"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.name), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  prop: "type",
                  label: "Type"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.userable_type), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  align: "center",
                  prop: "created_at",
                  label: "Created At"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.created_at != null)
                      ? (_openBlock(), _createBlock("div", _hoisted_6, _toDisplayString(_ctx.moment(scope.row.created_at).add(_ctx.moment(scope.row.created_at).utcOffset(),
                  'minute').format('YYYY-MM-DD HH:mm:ss')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  "header-align": "center",
                  align: "center",
                  label: "Action"
                }, {
                  default: _withCtx((scope) => [
                    _createVNode(_component_router_link, {
                      to: `/app/management/user/${scope.row.hash}/show`,
                      custom: ""
                    }, {
                      default: _withCtx(({ href, navigate }) => [
                        _createVNode(_component_el_tooltip, {
                          class: "box-item",
                          effect: "dark",
                          content: "Edit User",
                          placement: "left"
                        }, {
                          default: _withCtx(() => [
                            _createVNode("a", {
                              class: "btn btn-sm btn-icon btn-warning",
                              href: href,
                              onClick: navigate
                            }, [
                              _createVNode("span", _hoisted_7, [
                                _createVNode(_component_inline_svg, { src: _imports_0 })
                              ])
                            ], 8, ["href", "onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]),
            (_ctx.data && _ctx.data.current_page)
              ? (_openBlock(), _createBlock(_component_pagination, {
                  key: 0,
                  onChange: _ctx.handleChangePage,
                  ref: "paginationRef",
                  class: "mt-8",
                  "hide-when-only-one-page": true,
                  page: _ctx.data.current_page,
                  "per-page": _ctx.data.per_page ?? 0,
                  "total-current-page-item": _ctx.data.data.length,
                  "total-all-items": _ctx.data.total ?? 0
                }, null, 8, ["onChange", "page", "per-page", "total-current-page-item", "total-all-items"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _: 1
    })
  ]))
}